body, html, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

@keyframes blink {
  to {
    background-color: #e2e2e2;
  }
}

/* GENERAL */
.ml {
  margin-left: 20px
}

.mr {
  margin-right: 20px
}

.mb {
  margin-bottom: 20px
}

.mt {
  margin-top: 20px
}

.pa {
  padding: 20px
}

.pl {
  padding-left: 20px
}

.pr {
  padding-right: 20px
}

.pb {
  padding-bottom: 20px
}

.pt {
  padding-top: 20px
}

.wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:0 -15px;
}
.col {
  flex: 1;
  padding: 0 15px;
}
.flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.flex {
  flex: 1;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}


.input-control {
  margin-bottom: 6px
}